//=======================================================================
// FRONTEND LESS
//=======================================================================

//=> imports

@import 'bootstrap/bootstrap';
@import 'font-awesome/font-awesome';
@import 'fonts';

/*=================================
=            Variables            =
=================================*/

@azure: #15beb7;
@darkblue: #3b4350;
@gray: #999999;

/*=====  End of Variables  ======*/


//=======================================================================
// UNIVERSAL
//=======================================================================

html {
    overflow-y: scroll; // scrollbar fix (prevent ugly left pull)
}

body {
    position: relative;
    overflow-x: hidden;

    &.rtl * {
        //font-family: 'Open Sans Hebrew', sans-serif;
        font-family: 'Heebo', sans-serif;
    }

    &.ltr * {
        font-family: 'Noto Sans', sans-serif;
    }
}

#header #menu .nav.menu li:hover a,
.latestnews .slick-list .slick-slide *,
#header #menu .nav.menu li .dropdown-menu .submenu-inner li a:after,
#header #menu .nav.menu li a:after {
    -webkit-transition: all 300ms ease-in-out !important;
    -moz-transition: all 300ms ease-in-out !important;
    -ms-transition: all 300ms ease-in-out !important;
    -o-transition: all 300ms ease-in-out !important;
    transition: all 300ms ease-in-out !important;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}

ul.nav.menu {
    &>li {
        display: inline-block;

        a {
            * {
                display: none;
            }
        }
    }
}

.fixed-top {
    position: absolute;
    width: 100%;
    top: 0;

    &.fixed {
        position: fixed !important;
        top: 0;
        background: #fff;
    }
}

.fa {
    font-family: FontAwesome !important;
}

#offcanvas {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    z-index: -1;
    top: 89px;
    background: transparent;

    .container {
        max-width: 100%;
        right: -100%;
        // background: rgba(0, 102, 165, 0.8);
        width: 100%;

        .close-btn {
            text-align: right;

            img {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 50%;
            }
        }

        ul li {
            width: 100%;
            text-align: right;
            background: #959a9d;
            margin-bottom: 2px;
            border-bottom: 0px;

            a {
                border-bottom: 0px;
                color: #fff;
                text-transform: uppercase;
                font-size: 26px;
                background-color: transparent;


                .fa-caret-down {
                    display: none !important;
                }
            }
        }
    }

    &.opened {
        z-index: 999;
        background: rgba(88, 88, 88, 0.86);
        opacity: 1;
        overflow: auto;
        padding-bottom: 200px;
        padding-top: 20px;
    }

    .nav.menu {
        li {
            &.parent {
                .dropdown-menu {
                    position: relative;
                    background-color: transparent;
                    border: 0px;
                    box-shadow: none;
                    width: 100%;

                    li {
                        display: block !important;

                        &:last-child {
                            display: none !important;
                        }
                    }
                }
            }

            a {
                white-space: inherit;
                display: inline-block;
                vertical-align: middle;
            }

            i {
                display: inline-block;
                cursor: pointer;
                padding: 20px 20px;
            }
        }
    }
}

#offcanvas .nav.menu li:not(.deeper) a {
    display: block;
}

.container {
    width: 1200px;
    max-width: 100%;
}

.base-title {
    font-size: 28px;
    font-weight: 700;
    color: @darkblue;
    position: relative;
    padding-bottom: 40px;
    margin: 80px 0px 40px;

    &:before {
        content: '';
        background: url('../images/titles_before.png') no-repeat;
        height: 25px;
        width: 25px;
        float: right;
        position: relative;
        margin-left: 20px;
        max-width: 100%;
    }

    &:after {
        content: '';
        background: #dbd0ce;
        height: 2px;
        width: 70px;
        position: absolute;
        right: 0;
        top: 50px;
        max-width: 100%;
    }
}

.paraxify {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.azure {
    color: @azure;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.arrow-down {
    margin-top: 50px;
    cursor: pointer;
}

.nopadding {
    padding: 0;
}

.com-login {
    .login {
        margin-top: 100px;
    }
}

.margin20 {
    margin-bottom: 20px;
}

.inline-block {
    display: inline-block;
}

.center-text {
    text-align: center;
}

.con {
    cursor: pointer;
    display: inline-block;
}

.bar {
    display: block;
    height: 5px;
    width: 50px;
    background: #6FFFE9;
    margin: 10px auto;
}

.con {
    width: auto;
    margin: 0 auto;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -ms-transition: all .7s ease;
    -o-transition: all .7s ease;
    transition: all .7s ease;
}

.middle {
    margin: 0 auto;
}

.bar {
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -ms-transition: all .7s ease;
    -o-transition: all .7s ease;
    transition: all .7s ease;
}

.con:hover .top {
    -webkit-transform: translateY(15px) rotateZ(45deg);
    -moz-transform: translateY(15px) rotateZ(45deg);
    -ms-transform: translateY(15px) rotateZ(45deg);
    -o-transform: translateY(15px) rotateZ(45deg);
    transform: translateY(15px) rotateZ(45deg);
}

.con:hover .bottom {
    -webkit-transform: translateY(-15px) rotateZ(-45deg);
    -moz-transform: translateY(-15px) rotateZ(-45deg);
    -ms-transform: translateY(-15px) rotateZ(-45deg);
    -o-transform: translateY(-15px) rotateZ(-45deg);
    transform: translateY(-15px) rotateZ(-45deg);
}

.con:hover .middle {
    width: 0;
}

.mobile-menu {
    margin-top: 17px;
}

//=======================================================================
// CUSTOM
//=======================================================================
#header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    right: 0;

    .row-fluid.fixed-top {
        height: 94px;
        background: @azure;
    }

    #menu {
        padding-right: 0;

        .nav.menu {
            li {
                line-height: 74px;

                a {
                    background-color: transparent;
                    color: #fff;
                    font-size: 16px;
                    padding: 10px 25px;
                }

                &>a {
                    &:after {
                        content: '';
                        background: #fff;
                        width: 0;
                        height: 2px;
                        position: absolute;
                        bottom: 24px;
                        right: 22px;
                        opacity: 0;
                    }
                }

                &:hover {
                    &>a {
                        &:after {
                            width: 80%;
                            opacity: 1;
                        }
                    }
                }

                .dropdown-menu {
                    display: none;
                    width: 1920px;
                    max-width: 100%;
                    position: fixed;
                    top: 93px;
                    right: 0;
                    margin: 0;
                    border-radius: 0;
                    border: 0;
                    box-shadow: none;
                    background-color: rgba(255, 255, 255, 0.90);

                    .submenu-inner {
                        display: inline-block;
                        vertical-align: top;

                        li {
                            display: block;
                            line-height: 36px;

                            a {
                                color: #15beb7 !important;
                                position: relative;

                                &:after {
                                    content: '';
                                    background: @azure;
                                    width: 0;
                                    height: 2px;
                                    position: absolute;
                                    bottom: 0;
                                    right: 22px;
                                    opacity: 0;
                                }
                            }

                            &:hover {
                                a {
                                    font-weight: 400;

                                    &:after {
                                        width: 80%;
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        &:not(:nth-child(-n+2)):not(:last-child) {
                            display: none;
                        }
                    }
                }
            }
        }

        .col-sm-2 {
            text-align: right;
            margin-top: 30px;
            padding-right: 0;

            a {
                display: inline-block;
            }
        }
    }

    #logo {
        padding-left: 0;

        img {
            margin-top: 15px;
            margin-left: 0;
        }
    }
}

.latestnews-title {
    font-weight: 400;
}

.latestnews {
    padding: 0;
    margin-bottom: 100px;

    .slick-list {
        .slick-slide {
            position: relative;
            z-index: 0;

            span {
                position: absolute;
                top: 46%;
                width: 100%;
                text-align: center;
                opacity: 0;
                z-index: 2;
                color: #fff;
                font-size: 20px;
            }

            .overlay {
                z-index: 1;
                width: 100%;
                height: 100%;
                background: @azure;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
            }

            &:hover {

                span,
                .overlay {
                    opacity: 1;
                }
            }
        }
    }

    button[type="button"] {
        border: 0;
        background: url('../images/project_off.png') no-repeat;
        padding: 0;
        border-radius: 0;
        width: 36px;
        height: 35px;

        &:before {
            display: none;
        }

        &:hover {
            background: url('../images/project_on.png') no-repeat;
        }

        &.slick-prev {
            transform: rotateY(180deg);
            left: -45px;
        }

        &.slick-next {
            right: -45px;
        }
    }
}

.hp-image {
    position: relative;
    background-image: url('../images/hp_bg.jpg');
    height: 100vh;

    .hp-image-text {
        text-align: center;
        background: url('../images/hp_top_text_bg.png') no-repeat;
        background-size: cover;
        position: absolute;
        width: 431px;
        height: 429px;
        padding: 80px;
        margin-left: -80px;

        h2 {
            font-size: 42px;
            font-weight: 700;
            margin: 0;

            &.black {
                color: @darkblue;
                margin-top: 30px;
            }

            &.azure {
                color: @azure;
            }
        }

        h5 {
            font-size: 15px;

            &.gray {
                color: @gray;
            }

            &.black {
                line-height: 24px;
            }
        }

        img {
            //animation: MoveUpDown 1.5s linear infinite;
            position: absolute;
            left: 198px;
            bottom: 40px;
            padding: 40px 0px;
            cursor: pointer;
        }

        // @keyframes MoveUpDown {
        // 	0% {
        // 	bottom: 0px;
        // 	}
        // 	50% {
        // 	bottom: 80px;
        // 	}
        // 	100% {
        // 	bottom: 0px;
        // 	}
        // }
    }
}

#hp-text {
    height: 100vh;
    padding: 20vh 0 10vh;
    background-color: #f1f1f1;
    background-image: url('../images/about_studio_pic.png');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    z-index: 2;
    position: relative;

    .container {
        color: @darkblue;

        .row.plx-elm {
            padding-top: 5vh;

            .col-sm-9 {
                font-size: 18px;
                padding-left: 200px;
                font-weight: 300;
                padding-right: 0;

                .azure {
                    font-weight: 700;
                }

                h6 {
                    font-size: 18px;
                    font-weight: 800;
                    color: @darkblue;
                    line-height: 30px;
                    margin-top: 100px;
                    position: relative;

                    &:before {
                        content: ' “ ';
                        font-family: 'Heebo', sans-serif;
                        font-size: 72px;
                        position: relative;
                        -webkit-transform: rotateY(180deg);
                        transform: rotateZ(180deg);
                        margin-bottom: 0;
                        position: absolute;
                        top: -80px;
                    }
                }
            }

            .col-sm-3 {
                font-size: 20px;
                padding-right: 0;

                .row {
                    img {
                        margin-top: -8px;
                    }

                    .col-sm-6 {
                        strong {
                            display: block;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

#main-component {
    background-color: #f1f1f1;
    position: relative;

    .right-plx-img {
        position: absolute;
        top: 100px;
        right: 0;
        z-index: 1;
    }

    &>.container {
        z-index: 2;
        position: relative;
    }

    .left-plx-img {
        position: absolute;
        top: 400px;
        left: 0;
        z-index: 1;
    }
}

#our-team {
    padding: 100px 0px;

    .base-title {
        margin-top: 0;
    }

    .hp-team {
        .uk-panel {
            position: relative;

            &:after {
                content: '';
                background: url('../images/team_text_bg.png');
                width: 270px;
                height: 90px;
                position: absolute;
                bottom: 0;
                left: 7px;
            }

            .uk-h2,
            .uk-margin {
                z-index: 2;
                position: relative;
            }

            .uk-h2 {
                font-size: 20px;
                font-weight: 800;
                background: #f1f1f1;
                border: 1px solid @azure;
                max-width: 54%;
                margin: 0 auto;
                padding: 6px;
                margin-top: -58px !important;

                &:after {
                    content: '';
                    background: #f1f1f1;
                    width: 96%;
                    height: 82%;
                    border: 1px solid #15beb7;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    z-index: -1;
                }
            }

            .uk-margin {
                font-size: 16px;
                color: @darkblue;
                font-size: 16px;
                width: 100%;
                max-width: 270px;
                margin: 10px auto;
                background: #f1f1f1;

                p {
                    margin: 0;
                    opacity: 0.54;
                }
            }
        }

        .uk-grid-margin {
            margin-top: 20px;
        }
    }
}

#happy {
    position: relative;

    .successful-businesses {
        img.quote-left {
            position: absolute;
            left: 20%;
            top: 0;
        }

        img.quote-right {
            position: absolute;
            right: 20%;
            top: 60px;
        }

        img.center-block {
            width: 100%;
        }

        h3 {
            text-align: center;
            background: url('../images/happy_title_bg.jpg') no-repeat;
            background-size: cover;
            margin: 0;
            height: 160px;
            font-family: 'Archivo Narrow', sans-serif;
            font-size: 35px;
            color: #fff;
            line-height: 160px;
        }
    }

    .happy-clients-wrapper {
        border-bottom: 1px solid #eeeeee;

        .base-title {
            margin-top: 70px;
        }
    }

    .happy-clients {
        .uk-margin {
            p {
                color: #969696;
                font-size: 16px;
                text-align: right;
                background: #ececec;
                padding: 30px;
            }

            h4 {
                color: @azure;
                font-size: 20px;
                text-align: right;
                font-weight: 700;
                margin-top: 50px;
            }

            h5 {
                color: #969696;
                font-size: 16px;
                text-align: right;
            }
        }

        .uk-slidenav-position {
            .uk-slidenav {
                display: block;
                top: -50px;
                background: url('../images/happy_off.png') no-repeat;
                width: 36px;
                height: 35px;

                &:hover {
                    background: url('../images/happy_on.png') no-repeat;
                }

                &:before {
                    display: none;
                }

                &.uk-slidenav-previous {
                    left: 0;
                    transform: rotate(180deg);
                }

                &.uk-slidenav-next {
                    right: auto;
                    left: 50px;
                }
            }
        }
    }

    .clients-logos-wrapper {
        margin-top: 40px;

        .more-logos {
            cursor: pointer;
            margin-top: 40px;
        }

        .clients-group {
            margin-bottom: 20px;

            &:not(:nth-child(-n+2)) {
                display: none;
            }
        }
    }
}

/*----------  Homepage  ----------*/
.itemid-114 {
    .blog {
        img {
            width: 100%;
        }

        .items-leading {
            text-align: center;

            .leading-0,
            .leading-1 {
                display: inline-block;
            }

            .leading-0 {
                float: right;
            }

            .leading-1 {
                float: left;
            }
        }

        .items-row {
            margin-top: 24px;
            text-align: center;

            &:nth-child(even) {
                .span4 {
                    display: inline-block;

                    &:first-child {
                        float: left;
                        width: 50%;
                        max-width: 570px;
                    }

                    &:not(:first-child) {
                        width: 24%;
                        float: right;
                        margin-right: -11px;
                        margin-left: 30px;
                    }
                }
            }

            &:nth-child(odd) {
                .span4 {
                    display: inline-block;

                    &:first-child {
                        float: right;
                        width: 50%;
                        max-width: 570px;

                        img {
                            float: right;
                        }
                    }

                    &:not(:first-child) {
                        width: 24%;
                        float: left;
                        margin-right: 19px;
                    }
                }
            }
        }
    }

    .go-to-works {
        text-align: center;
        margin-bottom: -18px;
        margin-top: 60px;

        span {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 800;
        }
    }
}

.com-category {
    .blog {

        .page-header,
        p {
            display: none;
        }

        &>.page-header {
            display: block;
        }

        .items-row {
            &:nth-child(n+7) {
                display: none;
            }
        }
    }
}

/*======================================
=            Template Page            =
======================================*/

.template-1-wrapper,
.template-2-wrapper,
.template-3-wrapper,
.template-4-wrapper,
.template-5-wrapper {
    img {
        width: 100%;
    }

    .row {
        margin-bottom: 24px;

        .col-sm-6 {
            .col-sm-6 {}
        }
    }
}

.template-5-wrapper {
    .row {
        .col-sm-6 {
            .col-sm-6 {
                &:nth-child(1) {
                    padding-right: 0;
                }

                &:nth-child(2) {
                    padding-left: 0;
                }
            }
        }
    }
}

.com-article {
    background: #f1f1f1;

    section#slider,
    section#hp-text,
    section#our-team,
    section#happy,
    .go-to-works {
        display: none !important;
    }

    #main-component {
        padding-top: 94px;

        .intro-image {
            z-index: 2;
            position: relative;

            img {
                width: 100%;
            }
        }

        div[itemprop="articleBody"] {
            margin-bottom: 60px;
        }

        .item-page {
            font-size: 16px;

            ul.pager.pagenav {
                position: absolute;
                left: 20px;
                top: 80px;

                a {
                    text-indent: -9999px;
                }

                li {
                    a {
                        border: 0;
                        background: url('../images/project_off.png') no-repeat;
                        padding: 0;
                        border-radius: 0;
                        width: 36px;
                        height: 35px;
                    }

                    &.next {
                        margin-right: 10px;

                        a {
                            transform: rotateY(180deg);
                        }

                    }

                    &:hover {
                        a {
                            background: url('../images/project_on.png') no-repeat;
                        }
                    }
                }
            }

            .page-header {
                &:first-child {
                    display: none;
                }

                h1 {
                    display: none;
                }

                h2 {
                    font-size: 60px;
                    font-weight: 800;

                    &:before {
                        margin-top: 27px;
                    }

                    &:after {
                        top: 90px;
                    }
                }
            }
        }

    }
}

.fields-container {
    display: none;
}

/*=====  End of Template Page  ======*/


/*=============================================
=            Presentations Page            =
=============================================*/

.presentations {
    background-color: #fff;

    #main-component {
        background-color: #fff;

        >.plx-elm {
            display: none;
        }

        >.container {
            max-width: 100%;
            width: 100%;
            padding: 0;

            .main-wrapper {
                >.container {
                    h1 {
                        font-size: 60px;
                        font-weight: 900;
                        margin-top: 100px;
                        line-height: 29px;

                        &:after {
                            top: 53px;
                        }
                    }

                    >.text {
                        max-width: 711px;
                        font-size: 16px;
                        color: #3b4350;
                        line-height: 35px;
                        margin-bottom: 55px;
                    }

                    .presentations-wrapper {
                        >.row {
                            padding-bottom: 60px;
                            margin-bottom: 60px;
                            border-bottom: 1px solid #c1bbb9;

                            >div {
                                &.info {
                                    img {
                                        box-shadow: 0px 5px 10px 0px rgba(0, 1, 1, 0.5);
                                        margin-bottom: 120px;
                                    }

                                    .client-info {
                                        h3 {
                                            font-size: 16px;
                                            font-weight: 700;
                                            color: #15beb7;
                                        }

                                        p {
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #3b4350;
                                        }
                                    }
                                }

                                &.images {
                                    >.inner-cont {
                                        >.row {
                                            display: flex;
                                            flex-wrap: wrap;

                                            >div {
                                                margin-bottom: 12px;
                                                padding: 0px 6px;

                                                .inner-cont {
                                                    img {
                                                        box-shadow: 1px 1px 4px 0px rgba(0, 1, 1, 0.4);
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of Presentations Page  ======*/



/*==============================
=            Footer            =
==============================*/

#footer {
    padding: 93px 0;
    background: url('../images/footer_bg.png') no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;

    .center-text {
        margin: 40px 0px;
    }

    h2 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 40px;
    }

    .footer-middle {
        font-size: 16px;

        * {
            color: #fff;
        }

        p {
            margin-bottom: 20px;

            span {
                margin-left: 10px;
            }
        }
    }

    #copyrights {
        .fox-item {
            float: none;

            .asterisk {
                display: none;
            }

            .controls {
                float: none;
                width: 100%;
            }

            input,
            textarea,
            .btn {
                width: 100%;
                margin: 0;
                height: 50px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                background-color: transparent;
                border-radius: 0px;
                font-size: 16px;
                color: #fff;
                font-weight: 400;
                padding-right: 10px;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }

            textarea {
                height: 120px !important;
            }

            .btn {
                color: #fff;
                font-weight: 700;
                background: @azure;
                text-shadow: none;
                box-shadow: none;
                border: 0;

                span {
                    color: #fff;
                }
            }

            &.fox-item-text-area {
                margin-bottom: 14px;
            }

            .fox-messages {
                text-align: right;
            }
        }
    }
}

/*=====  End of Footer  ======*/

/*==================================
=            Responsive            =
==================================*/

.tags-wrapper {
    .tagsarticles {
        >ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 20px;
            justify-content: center;
            margin-bottom: 50px;

            li {
                font-size: 18px;
                background-color: #15beb7;
                color: #fff;
                padding: 6px 20px;
                cursor: pointer;
            }
        }

        .card-columns {
            column-count: 3;
            column-gap: 15px;
            orphans: 1;
            widows: 1;

            @media (max-width: 992px) {
                column-count: 2;
            }

            @media (max-width: 768px) {
                column-count: 1;
            }

            .card {
                margin-bottom: 15px;
                padding: 0;
                display: inline-block;
                width: 100%;

                .inner-cont {
                    &:hover {

                        .content,
                        .hover-action::after {
                            opacity: 1 !important;
                            transition: all linear 0.2s;
                        }
                    }

                    .date {
                        font-size: 2rem;
                        color: #fff;
                        margin-bottom: 0;
                        background-color: rgba(#F2F2F2, 0.63);
                        text-align: center;
                        padding: 6px;
                        position: absolute;
                        z-index: 9999;
                        width: 100%;
                        font-weight: 700;
                        text-shadow: 1px 1px 2px black;
                    }

                    .hover-action {
                        position: relative;
                        // border-bottom: 8px solid #ee7501;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        &::after {
                            content: '';
                            background-color: #15beb7;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: block;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }

                        .content {
                            background-color: transparent;
                            // border-bottom: 8px solid #EE7501;
                            padding: 1rem;
                            padding-bottom: 3rem;
                            position: relative;
                            min-height: auto;
                            position: absolute;
                            color: #fff;
                            opacity: 0;
                            position: absolute;
                            border: 0;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            width: fit-content;
                            height: auto;
                            transform: translate(-50%, -50%);
                            z-index: 1;

                            .row {
                                margin: 0;
                                justify-content: center;
                                align-items: center;
                                column-gap: 1rem;

                                >div {
                                    line-height: initial;
                                    margin: 0;
                                    padding: 0;
                                }
                            }

                            @include respond(768) {
                                padding-bottom: 5rem;
                            }

                            .description {
                                line-height: 3.2rem;
                                font-size: 2.4rem;
                                font-weight: 400;
                                color: #fff;
                            }

                            .read-more {
                                position: absolute;
                                right: 2rem;
                                bottom: 1rem;
                                display: flex;
                                flex-wrap: wrap;
                                font-size: 1.8rem;
                                color: #fff;

                                div {
                                    font-size: 22px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/*=====  End of Responsive  ======*/
@media (max-width: 1600px) {
    #hp-text {
        padding: 0;

        .container {
            .row.plx-elm {
                padding: 0 !important;
                transform: inherit !important;
            }
        }
    }

    #happy {
        .successful-businesses {
            img.quote-right {
                top: 120px;
            }
        }
    }

    #footer {
        padding: 63px 0 30px;
    }
}

@media (max-width: 1400px) {
    .container {
        width: 1140px;
        max-width: 100%;
    }

    .itemid-114 .blog .items-leading .leading-0,
    .itemid-114 .blog .items-leading .leading-1,
    .itemid-114 .blog .items-row:nth-child(even) .span4,
    .itemid-114 .blog .items-row:nth-child(odd) .span4,
    .itemid-114 .blog .items-row:nth-child(odd) .span4:first-child,
    .itemid-114 .blog .items-row:nth-child(even) .span4:first-child {
        width: 49%;
    }

    .itemid-114 .blog .items-row:nth-child(odd) .span4:not(:first-child),
    .itemid-114 .blog .items-row:nth-child(even) .span4:not(:first-child) {
        width: 23%;
    }

    .itemid-114 .blog .items-row:nth-child(even) .span4:not(:first-child) {
        margin-right: 0px;
        margin-left: 27px;
    }

    #our-team .hp-team .uk-panel:after {
        left: 0;
    }
}

@media (max-width: 1200px) {
    #hp-text {
        padding: 0 15px 40px;
        height: auto;
    }

    .itemid-114 .blog .items-row:nth-child(even) .span4:not(:first-child) {
        margin-right: 0;
        margin-left: 22px;
    }

    #our-team .hp-team .uk-panel {
        &:after {
            width: 100%;
            background-color: #f1f1f1;
        }

        .uk-h2 {
            max-width: 74%;
        }
    }

    #happy {
        .successful-businesses {
            img.quote-left {
                left: 40px;
            }

            img.quote-right {
                top: 80px;
                right: 10px;
            }
        }
    }
}

@media (max-width: 992px) {

    .presentations {
        #main-component {
            >.container {
                .main-wrapper {
                    >.container {
                        h1 {
                            font-size: 50px;
                        }

                        .presentations-wrapper {
                            >.row {
                                >div.info {
                                    img {
                                        margin-bottom: 20px;
                                    }

                                    .client-info {
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-image-text {
        left: 40% !important;
    }

    #header {
        #menu {
            width: 60%;
        }

        #logo {
            width: 40%;
        }
    }

    #hp-text {
        .container {
            .row.plx-elm {
                .col-sm-9 {
                    width: 60%;
                    padding-left: 50px;
                }

                .col-sm-3 {
                    width: 40%;
                }
            }
        }
    }

    .itemid-114 .blog .items-row:nth-child(odd) .span4:not(:first-child) {
        margin-right: 18px;
    }

    .itemid-114 .blog .items-row:nth-child(even) .span4:not(:first-child) {
        margin-left: 18px;
    }

    #happy .successful-businesses img.quote-right {
        max-width: 60px;
    }
}

@media (max-width: 768px) {
    #header {
        #logo {
            width: 59%;
            display: inline-block;
        }

        #menu {
            width: 40%;
            display: inline-block;
        }
    }

    .hp-image-text {
        left: 50% !important;
        transform: scale(0.8) translate(-63%, -50%);
        margin: 0 !important;

    }

    #hp-text {
        .container {
            .row.plx-elm {
                margin: 0;

                .col-sm-3,
                .col-sm-9 {
                    width: 100%;
                    padding-left: 0;
                }

                .col-sm-3 {
                    .col-sm-6 {
                        display: inline-block;
                        width: 49%;
                        vertical-align: text-top;
                    }
                }
            }
        }
    }

    .itemid-114 .blog .items-row:nth-child(odd) .span4:not(:first-child) {
        margin-right: 7px;
    }

    .itemid-114 .blog .items-row:nth-child(even) .span4:not(:first-child) {
        margin-left: 7px;
    }

    #our-team .hp-team .uk-panel {
        max-width: 270px;
        margin: 0 auto;
    }

    #happy {
        .successful-businesses {
            h3 {
                line-height: 50px;
                padding-top: 27px;
            }

            img {
                display: none;
            }
        }

        .clients-logos-wrapper .clients-group {
            margin-bottom: 0;

            .col-sm-2 {
                width: 49%;
                display: inline-block;
                margin-bottom: 30px;
            }
        }
    }

    .fox-container {
        .fox-row {
            width: 100%;
            display: table;

            .fox-column {
                width: 100%;
                display: table-footer-group;
            }
        }
    }
}

@media (max-width: 468px) {
    .presentations {
        #main-component {
            >.container {
                .main-wrapper {
                    >.container {
                        h1 {
                            font-size: 40px;
                            margin-top: 40px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}