@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 300;
    src: url(../fonts/opensanshebrew-lightitalic-webfont.eot);
    src: url(../fonts/opensanshebrew-lightitalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-lightitalic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-lightitalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/opensanshebrew-light-webfont.eot);
    src: url(../fonts/opensanshebrew-light-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-light-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-light-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 400;
    src: url(../fonts/opensanshebrew-italic-webfont.eot);
    src: url(../fonts/opensanshebrew-italic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-italic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-italic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/opensanshebrew-regular-webfont.eot);
    src: url(../fonts/opensanshebrew-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-regular-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-regular-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 700;
    src: url(../fonts/opensanshebrew-bolditalic-webfont.eot);
    src: url(../fonts/opensanshebrew-bolditalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-bolditalic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-bolditalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/opensanshebrew-bold-webfont.eot);
    src: url(../fonts/opensanshebrew-bold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-bold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-bold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 800;
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot);
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 800;
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot);
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}

//? Heebo
@font-face {
    font-family: 'Heebo';
    src: local('Heebo Light'), local('Heebo-Light'),
        url('../fonts/Heebo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Regular'), local('Heebo-Regular'),
        url('../fonts/Heebo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo Bold'), local('Heebo-Bold'),
        url('../fonts/Heebo-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: local('Heebo ExtraBold'), local('Heebo-ExtraBold'),
        url('../fonts/Heebo-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

//? Archivo Narrow
@font-face {
    font-family: 'Archivo Narrow';
    src: local('Archivo Narrow Regular'), local('ArchivoNarrow-Regular'),
        url('../fonts/ArchivoNarrow-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archivo Narrow';
    src: local('Archivo Narrow Bold'), local('ArchivoNarrow-Bold'),
        url('../fonts/ArchivoNarrow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

